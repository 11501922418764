//export const apiUrl = 'https://www.changetheagency.se/sommarfest2022/?';
export const apiUrl = 'https://be.change.randomstate.se/?';


export const getTeam = async (teamName) => {
  const response = await fetch(apiUrl + new URLSearchParams({
    a: 'team',
    team: teamName
  }))
  const data = await response.json();
  if (response.status == 200) {
    return data;
  }
  return null;
};

export const getQuestion = async (teamName, ball) => {
  const response = await fetch(apiUrl + new URLSearchParams({
    a: 'ball',
    team: teamName,
    ball: ball
  }))
  const data = await response.json();
  if (response.status == 200) {
    return data;
  }
  return null;
};
/*
export const answerQuestion = async (teamName, ball, answer) => {
  const response = await fetch(apiUrl + new URLSearchParams({
    a: 'answer',
    team: teamName,
    ball: ball,
    answer: answer
  }))
  const data = await response.json();
  if (response.status == 200) {
    return data;
  }
  return null;
};
*/
export const answerQuestion = async (teamName, ball, answer, files) => {
  const fData = new FormData();
  fData.append('a', 'answer');
  fData.append('team', teamName);
  fData.append('ball', ball);
  fData.append('answer', answer);
  fData.append('image', files);

  const response = await fetch(apiUrl, {
    method: 'POST',
    body: fData
  });
  const data = await response.json();
  if (response.status == 200) {
    return data;
  }
  return null;
};
