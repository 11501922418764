<template>
  <div class="question overlay" :class="(question.length <= 0)?'loading':''">
    <h4>BOLL {{ ball }}</h4>
    <h1>{{ question }}</h1>

    <p v-if="!postImage"><input type="text" v-model="answer" /></p>
    <p v-if="postImage"><input type="file" v-on:change="onFileChange" capture="user" accept="image/png, image/jpeg" /></p>

    <button @click="send" class="answer" :disabled="disableButton">Svara</button>
    <button @click="close" class="close"></button>
    <h4 v-if="wrongAnswer" class="error">Fel svar!</h4>
  </div>
</template>

<script>
import { getQuestion, answerQuestion } from '@/helpers/api';

export default {
  name: 'Question',
  emits: ["done", "close"],
  mounted() {
    if (this.ball > 0) {
      // Load question
      this.loadQuestion();
    }
  },
  computed: {
    disableButton() {
      if (this.uploading) return true;
      if (this.postImage) {
        return (this.files.length <= 0);
      }
      return (this.answer.trim().length <= 0);
    },
    hasAnswered() {
      return this.answer.trim().length > 0;
    }
  },  
  data() {
    return { 
      question: '',
      postImage: false,
      answer: '',
      files: '',
      wrongAnswer: false,
      uploading: false,
    }
  },
  props: {
    team: String,
    ball: Number,
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.files = files[0];
    },
    loadQuestion() {

      getQuestion(this.team, this.ball)
      .then((response) => {
        if (response != null) {
console.log(response);
          this.question = response.question;
          this.postImage = (parseInt(response.postImage) == 1);
        }
      });


//      this.$emit("start");
    },
    send() {
      this.uploading = true;

      answerQuestion(this.team, this.ball, this.answer, this.files)
      .then((response) => {
        if (response != null && response.status == "ok") {
console.log(response);
          this.$emit('done', this.ball);
          this.uploading = false;
        } else {
          this.answer = "";
          this.wrongAnswer = true;
          this.uploading = false;
        }
      });
    },
    close() {
      this.$emit('close');
    }
  },
  watch: {
    ball(oldBall, newBall) {
      if (newBall > 0) {
        // Load question
        this.loadQuestion();
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

#header {
  max-width: 80%;
  height: auto;
  margin: 2em 0;
}
.question {
  padding: 4em 2em 2em;
}
h4 {
  font-size: 2em;
  margin: 0 0 0.5em;
  color: var(--color-red);
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

h1 {
  font-family: 'Caveat', cursive;
  font-size: 2em;
  text-align: left;
  white-space: pre-line;
}

.close {
  position: absolute;
  top: 0em;
  right: 1em;
  color: rgba(0,0,0,0);
  height: 1em;
  width: 1em;
  padding: 1.5em 1.5em;

  &:before, &:after {
    position: absolute;
    top: 0.85em;
    left: 1.35em;
    content: ' ';
    height: 1.5em;
    width: 0.25em;
    background-color: var(--color-button-text);
    border-radius: 0.5em;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
</style>
