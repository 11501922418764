<template>
  <div class="hello">
    <h3>Felaktig länk!</h3>
    <p>
      Kolla mejlet och försök igen!
    </p>
  </div>
</template>

<script>
export default {
  name: 'CodeFail',
}
</script>