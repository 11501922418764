<template>
  <div class="game" :class="[showBingo?'show-bingo':'hide-bingo']">
    <div id="bingo" v-show="showBingo"><Bingocard :card="card" :taken="taken" :takenFirst="takenFirst" /></div>
    <div id="map" v-show="!showBingo"></div>
    <div class="newball" v-if="newlyAddedBall > 0">
      <div class="marker marker-anim" :class="'marker-col-'+Math.ceil(newlyAddedBall/15)" :style="'transform: translate(' + newlyAddedPosition.x + 'px,' + newlyAddedPosition.y + 'px);'">{{ newlyAddedBall }}</div>
    </div>
    <div id="tabs">
      <a @click="activateMap" class="map" :class="[showBingo?'':'active']">Karta</a>
      <a @click="activateBingo" class="bingo" :class="[showBingo?'active':'']">Bingobricka</a>
    </div>
    <Question v-if="showQuestion > 0" @close="hideQuestion" @done="doneQuestion" :team="team" :ball="showQuestion" />
    <InfoOverlay v-if="showInstructions" @close="hideInstructions" />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { getDistanceFromLatLonInMeters, rad2deg } from '@/helpers/map';
import Bingocard from '../components/Bingocard.vue';
import Question from '../components/Question.vue';
import InfoOverlay from '../components/InfoOverlay.vue';
import { getTeam } from '@/helpers/api';
import { gsap } from "gsap";

/*
01, 05, 06, 09, 12
16, 19, 21, 23, 27
31, 32, 38, 40, 42
48, 49, 54, 57, 58
61, 69, 70, 71, 73
*/

export default {
  name: 'Game',
  mounted() {
    this.createMap();

    this.loadTeam();

    setInterval(this.loadTeam, 30000); // Reload team info every 30 seconds
  },
  computed: {
    coordinates() {
      return [this.longitude, this.latitude];
    }
  },
  data() {
    return {
      showInstructions: true,
      showBingo: false,
      map: {},
      mapLoaded: false,
      mapConfig: {
        container: 'map',
        accessToken: "pk.eyJ1IjoiY2hhbmdldGhlYWdlbmN5IiwiYSI6ImNqeGszdjhzNjAxNmMzcG41dWF4eGc0bmgifQ.y02J7eV1700VhMASlMpXpg",
        style: "mapbox://styles/changetheagency/cljdeqi43002h01qs9fpf64eh",
        center: [11.967426, 57.703714],
        zoom: 15,
        minZoom: 13,
        maxZoom: 17,
        crossSourceCollisions: false,
        failIfMajorPerformanceCaveat: false,
        attributionControl: false,
        preserveDrawingBuffer: true,
        hash: false,
        pitch: 60,
        minPitch: 60,
        maxPitch: 60,
        dragPan: false,
        pitchWithRotate: false,
        scrollZoom: false,
        boxZoom: false,
        dragRotate: false,
        keyboard: false,
        doubleClickZoom: false,
        touchZoomRotate: false,
      },
      markers: {},
      myPositionMarker: null,
      nearBall: null,
      showQuestion: null,
      card: [],
      taken: [],
      takenFirst: [],
      ballsAvailable: [],
      startBearing: 0,
      startAngle: 0,
      startTouchPosition: 0,
      newlyAddedBall: null,
      newlyAddedPosition: [-100, 300],
    }
  }, 
  props: {
    position: Array,
    team: String,
  },
  watch: {
    position(oldpos, newpos) {
      console.log('Game::position updated');
      this.updateMyPosition(newpos);
    },
  },
  methods: {
    createMap() {
      mapboxgl.accessToken = this.mapConfig.accessToken;
      this.map = new mapboxgl.Map(this.mapConfig);
      
      this.map.on('load', this.onMapLoaded);

      let mapCanvas = this.map.getCanvasContainer();
      mapCanvas.addEventListener('touchstart', this.customTouchStart);
    },
    customTouchStart(e) {
      let mapCanvas = this.map.getCanvasContainer();
      const rect = mapCanvas.getBoundingClientRect();
      const t = window.TouchEvent && (e instanceof window.TouchEvent) ? e.touches[0] : e;
      this.startTouchPosition = [
        t.clientX - rect.left - mapCanvas.clientLeft,
        t.clientY - rect.top - mapCanvas.clientTop,
      ];
      this.startAngle = Math.atan2(
        (rect.height / 2) - this.startTouchPosition[1],
        (rect.width / 2) - this.startTouchPosition[0],
      ) * (180 / Math.PI);
      this.startBearing = this.map.getBearing();

      mapCanvas.addEventListener('touchmove', this.customTouchMove);
      mapCanvas.addEventListener('touchend', this.customTouchEnd);

      return true;
    },
    customTouchMove(e) {
      let mapCanvas = this.map.getCanvasContainer();
      const rect = mapCanvas.getBoundingClientRect();
      const t = window.TouchEvent && (e instanceof window.TouchEvent) ? e.touches[0] : e;
      const currentTouchPosition = [
        t.clientX - rect.left - mapCanvas.clientLeft,
        t.clientY - rect.top - mapCanvas.clientTop,
      ];
      const currentAngle = rad2deg(Math.atan2(
        (rect.height / 2) - currentTouchPosition[1],
        (rect.width / 2) - currentTouchPosition[0],
      ));

      this.map.setBearing(this.startBearing + 3 * (this.startAngle - currentAngle));
      return true;
    },
    customTouchEnd() {
      let mapCanvas = this.map.getCanvasContainer();
      mapCanvas.removeEventListener('touchmove', this.customTouchMove);
      mapCanvas.removeEventListener('touchend', this.customTouchEnd);
    },

    createMarker(lng, lat, imageName, isTakenByAnotherTeam) {
      let num = Number(imageName);
      if (isNaN(num)) { num = -1; }
      const el = document.createElement('div');
      el.className = 'marker marker-col-'+Math.ceil(num/15)+' marker-'+imageName+(isTakenByAnotherTeam || imageName=='me' ? '' : ' marker-not-taken');

      const textContent = document.createTextNode(String(imageName));
      el.appendChild(textContent);

      el.dataset.ball = num;
      el.addEventListener('click', this.markerClicked)

      const marker = new mapboxgl.Marker({ element: el, anchor: imageName=='me'?'bottom':'center' })
        .setLngLat([lng, lat])
        .addTo(this.map);
      return marker;
    },
    markerClicked(e) {
      let el = e.target;
      const ballNum = el.dataset.ball;
      if (ballNum == this.nearBall) {
        this.showQuestion = parseInt(ballNum);
      }
    },
    updateMyPosition(latLngPosition) {
      if (this.myPositionMarker) this.myPositionMarker.setLngLat(latLngPosition);
      this.map.panTo(latLngPosition, {duration: 1000}); //.setCenter(latLngPosition);

      this.checkNearByBalls(latLngPosition);
    },
    checkNearByBalls(latLngPosition) {
      const DISTANCE_TO_BALL = 80.0; 
      let totalBallsNear = 0;
      for (let key in this.markers) {
        console.log(key);
        let marker = this.markers[key];
        let mpos = marker.getLngLat();

//        const distLong = Math.abs(latLngPosition.lng - mpos.lng);
//        const distLat = Math.abs(latLngPosition.lat - mpos.lat);
//        const distanceToPosition = Math.sqrt(distLong * distLong + distLat * distLat);
        const distanceInMeters = getDistanceFromLatLonInMeters(latLngPosition[0], latLngPosition[1], mpos.lng, mpos.lat);

        if (distanceInMeters < DISTANCE_TO_BALL) {
          // HOORAY Load ball question. And pause maps while dialog is shown?
          this.nearBall = key;

          let el = marker.getElement();
          el.classList.add('marker-near');
          totalBallsNear++;
        }
      }
      if (totalBallsNear < 1 && this.nearBall != null) {
        let marker = this.markers[this.nearBall];
        let el = marker.getElement();
        el.classList.remove('marker-near');

        this.nearBall = null;
      }
    },
    onMapLoaded() {
      console.log('onMapLoaded');
      this.mapLoaded = true;
      this.myPositionMarker = this.createMarker(this.position[0], this.position[1], 'me', false);
/*
      this.markers[9] = this.createMarker(11.960426, 57.701714, '9');
      this.markers[27] = this.createMarker(11.964426, 57.702314, '27');
      this.markers[36] = this.createMarker(11.963426, 57.704814, '36');
      this.markers[53] = this.createMarker(11.968426, 57.7097714, '53');
      this.markers[71] = this.createMarker(11.966426, 57.707714, '71');
*/
      this.updateMyPosition(this.position);
    },
    createMarkers() {
      for(let i=0; i<this.ballsAvailable.length; i++) {
        let b = this.ballsAvailable[i];
        const ballNum = parseInt(b.ball);
        if (this.markers[ballNum]) {
          // Marker already created. Show it if its hidden
          let marker = this.markers[ballNum];
          marker.addTo(this.map);

          let el = marker.getElement();
          if (parseInt(b.numberTaken)>0) {
            el.classList.remove('marker-not-taken');
          } else {
            el.classList.add('marker-not-taken');
          }
        } else {
          // Create marker and add to map
          this.markers[ballNum] = this.createMarker(b.lng, b.lat, String(ballNum), parseInt(b.numberTaken)>0);
        }
      }

        // Loop over and hide markers not still available?
    },
    removeMarker(ballNum) {
        if (this.markers[ballNum]) {
          this.markers[ballNum].remove();
        }
    },
    approveGps() {
    },
    activateMap() {
      this.showBingo = false;
    },
    activateBingo() {
      this.showBingo = true;
    },
    hideQuestion() {
      this.showQuestion = null;
    },
    hideInstructions() {
      this.showInstructions = false;
    },
    doneQuestion() {
      console.log('doneQuestion', this.showQuestion);
      let marker = this.markers[this.showQuestion];
      if (marker) {
        this.newlyAddedBall = this.showQuestion;
        this.newlyAddedPosition = this.map.project(marker.getLngLat());
        gsap.to(this.newlyAddedPosition, { x: window.innerWidth * 0.75, y: window.innerHeight, ease: 'back.in(1.4)', duration: 2, onComplete: this.hideNewlyAddedBall });
      }
      this.removeMarker(this.showQuestion);
      this.showQuestion = null;
      // Reload Team data
      this.loadTeam();
    },
    hideNewlyAddedBall() {
      this.newlyAddedBall = null;
      this.newlyAddedPosition = null;
    },
    bingocardStringToArray(str) {
      let balls = str.split(',');
      let rows = [[], [], [], [], []];
      for(let i=0; i<balls.length; i++) {
        rows[Math.floor(i/5)].push(parseInt(balls[i]));
      }
      return rows;
    },
    loadTeam() {
// console.log('loadTeam');

      getTeam(this.team)
      .then((teamData) => {
        if (teamData != null) {
// console.log(teamData);
          this.card = this.bingocardStringToArray(teamData.bingocard);
          for(let i=0; i<teamData.taken.length; i++) {
            teamData.taken[i] = parseInt(teamData.taken[i]);
          }
          for(let i=0; i<teamData.takenfirst.length; i++) {
            teamData.takenfirst[i] = parseInt(teamData.takenfirst[i]);
          }

          this.taken = teamData.taken;
          this.takenFirst = teamData.takenfirst;
          this.ballsAvailable = teamData.balls;

          this.createMarkers();
        }
      });
    }
  },
  components: {
    Bingocard,
    Question,
    InfoOverlay
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import "~mapbox-gl/dist/mapbox-gl.css";

  .marker {
    width: 30px;
    height: 30px;
    background: transparent url(../assets/ball-lightblue.png) no-repeat center center;
    background-size: contain;
    color: #333;

    &.marker-near {
      animation:  marker-near-pulse 1s linear 0s infinite;
    }
  }

  .marker-col-1,
  .marker-col-2,
  .marker-col-3,
  .marker-col-4,
  .marker-col-5 {
    font-weight: bold;
    padding: 7px 0 0 4px;
    font-size: 11px;
  }

  .newball {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .marker {
      position: absolute;
      padding: 7px 0 0 4px;
//      padding: 10px 0 0 4px;
    }
  }

  .marker-col-1 { //1-15
//    background-image: url(../assets/blob-lightblue.png);
    background-image: url(../assets/ball-lightblue.png);
  }
  .marker-col-2 { //16-30
//    background-image: url(../assets/blob-yellow.png);
    background-image: url(../assets/ball-orange.png);
  }
  .marker-col-3 { //31-45
//    background-image: url(../assets/blob-green.png);
    background-image: url(../assets/ball-green.png);
  }
  .marker-col-4 { //46-60
//    background-image: url(../assets/blob-blue.png);
//    color: var(--color-white);
    background-image: url(../assets/ball-blue.png);
  }
  .marker-col-5 { //61-75
//    background-image: url(../assets/blob-red.png);
    background-image: url(../assets/ball-pink.png);
  }

  .marker-not-taken {
    border: 2px solid var(--color-gold);
    padding: 5px 0 0 2px;
    border-radius: 50%;
  }

  .marker-me {
    pointer-events: none;
    width: 40px;
    height: 80px;
    background: transparent url(../assets/mypos.png) no-repeat bottom center;
    background-size: contain;
    font-size: 0;

    &:after {
      position: absolute;
      content: '';
      transform: translate(-50%, 88%);
      width: 100px;
      height: 60px;
      background: radial-gradient(ellipse, rgba(242,240,136,0) 60%, rgba(242,240,136,0.5) 69%, rgba(242,240,136,0) 70%);
      animation:  marker-me-pulse 2s linear 0s infinite;
    }
  }

  @keyframes marker-me-pulse {
    0% {
      opacity: 0;
      transform: translate(-50%, 73%) scale(0.18, 0.18);
    }
    20% {
      opacity: 1;
      transform: translate(-50%, 73%) scale(0.3, 0.3);
    }
    80% {
      opacity: 0.6;
      transform: translate(-50%, 73%) scale(0.75, 0.75);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, 73%) scale(0.9, 0.9);
    }
  }


  @keyframes marker-near-pulse {
    0%, 100% {
      width: 30px;
      height: 30px;
      font-size: 11px;
      padding: 7px 0 0 4px;
    }
    50% {
      width: 45px;
      height: 45px;
      font-size: 15px;
      padding: 15px 0 0 5px;
    }
  }
</style>

<style lang="scss" scoped>
  .game {
    width: 100%;
    height: 100vh;
  }
  #map {
    width: 100%;
    height: 100%;
  }

  #tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: var(--color-green);
    color: var(--color-white);
    display: flex;
    flex-direction: row;

    a {
      display: block;
      width: 50%;
      padding: 3em 0 1em;
//      font-family: 'TheCircusShow';
//      font-size: 1.5em;
      font-size: 1em;
      position: relative;

      &.map {
        background: url(../assets/icon-map.png) no-repeat center top 1em;
        background-size: 30px;

        &.active {
          background-image: url(../assets/icon-map-selected.png);
        }
      }
      &.bingo {
        background: url(../assets/icon-card.png) no-repeat center top 1em;
        background-size: 30px;

        &.active {
          background-image: url(../assets/icon-card-selected.png);
        }
      }

      &.active {
//        background: #ce1417;
        color: var(--color-red);

        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(26, 141, 124, 0);
          border-bottom-color: var(--color-green);
          border-width: 8px;
          margin-left: -8px;
        }
      }
    }
  }
</style>
