<template>
  <div class="bingocard">
    <table class="card">
      <tr v-for="(row, index) in card" :key="`row-${index}`">
        <td v-for="(column, index2) in row" :key="`col-${index2}`" :class="[isTaken(column)?'taken':'', 'box-'+index+'-'+index2, isTakenFirst(column)?'taken-first':'']">
          {{ column }}
        </td>
      </tr>
    </table>
    <div class="stats">
      <p>Bollar: 1p/st - {{ taken.length }} st</p>
      <p class="gold">Först på bollen: 1p/st - {{  takenFirst.length  }} st</p>
      <p>Rader: 5p/st - {{ fullRows }} st</p>
      <p>Fyra hörn: 10p - {{ hasCorners }} st</p>
      <h2>Total poäng - {{ score }} p</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bingocard',
  emits: ["start"],
  data() {
    return { 
/*      
      card: [
        [ 1, 5, 6, 9, 12 ],
        [ 16, 19, 21, 23, 27 ],
        [ 31, 32, 38, 40, 42 ],
        [ 48, 49, 54, 57, 58 ],
        [ 61, 69, 70, 71, 73 ]
      ],
      taken: [ 9, 19, 27, 38, 54, 58, 61, 48, 16, 31, 69, 1, 57, 49, 23, 12 ]
*/
    }
  },
  props: {
    card: Array,
    taken: Array,
    takenFirst: Array,
  },
  methods: {
    isTaken(columnNum) {
      const num = Number(columnNum);
      return this.taken.includes(num);
    },
    isTakenFirst(columnNum) {
      const num = Number(columnNum);
// console.log('TF', this.takenFirst, columnNum);
// eslint-disable-next-line no-debugger
//debugger;
      return this.takenFirst.includes(num);
    },
    checkRow(rowNum) {
      for (let i = 0; i < 5; i++) {
        if (!this.taken.includes(this.card[rowNum][i])) return false;
      }
      return true;
    },
    checkColumn(colNum) {
      for (let i = 0; i < 5; i++) {
        if (!this.taken.includes(this.card[i][colNum])) return false;
      }
      return true;
    },
    checkDiagonalTL() {
      for (let i = 0; i < 5; i++) {
        if (!this.taken.includes(this.card[i][i])) return false;
      }
      return true;
    },
    checkDiagonalBL() {
      for (let i = 0; i < 5; i++) {
        if (!this.taken.includes(this.card[4-i][i])) return false;
      }
      return true;
    },
    checkCorners() {
      let corners = 0;
      if (this.taken.length == 0 || this.card.length == 0) return false; 
      if (this.taken.includes(this.card[0][0])) corners++;
      if (this.taken.includes(this.card[0][4])) corners++;
      if (this.taken.includes(this.card[4][0])) corners++;
      if (this.taken.includes(this.card[4][4])) corners++;

      return (corners == 4);
    }
  },
  computed: {
    hasCorners() {
      return this.checkCorners() ? 1 : 0;
    },
    fullRows() {
      let numRows = 0;
      if (!this.card || this.card.length < 5) return 0;

      for(let i=0; i<5; i++) {
        if (this.checkRow(i)) { numRows++ }
        if (this.checkColumn(i)) { numRows++ }
      }
      if (this.checkDiagonalBL()) { numRows++ }
      if (this.checkDiagonalTL()) { numRows++ }

      return numRows;
    },
    score() {
      return this.hasCorners*10 + this.fullRows*5 + this.taken.length + this.takenFirst.length;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.bingocard {
  padding-top: 1.5em;
  height: 100vh;
  background: var(--color-blue-dark) url(../assets/2023majorna-outline.png) no-repeat center bottom;
  background-size: contain;
}

h1 {
  padding-top: 1em;
}
h2 {
  text-transform: uppercase;
  color: var(--color-red);
  margin: 1em 0;
}
.card {
  margin: 0px auto 1em;
  padding: 109px 59px 33px 54px;
  width: 360px;
//  min-height: 400px; 
  background: transparent url(../assets/2023card.png) no-repeat center center;
  background-size: contain;

  td {
    padding: 0.36em 0;
    text-align: center;
    font-size: 24px;
    font-weight: bolder;
    border-radius: 3px;
    color: var(--color-blue-dark);
    font-family: var(--font-header);

        
    &.box-0-0,
    &.box-0-4,
    &.box-4-0,
    &.box-4-4 {
      background-color: var(--color-yellow);
      background: radial-gradient(circle, rgba(255,228,69,1) 24%, rgba(255,228,69,0) 80%);
//      background-color: rgba(255, 209, 0, 0.2); //var(--color-yellow-light); 
    }
    &.taken {
      border-radius: 50% 50%;
      padding: 0.68em 0.2em 0.8em 0;
      font-size: 17px;

      color: #453f19;
      background: radial-gradient(ellipse at 46% 46%, rgb(224 221 208 / 99%) 0%, rgba(255 255 255 / 80%) 37%, rgba(255,255,255,0) 38%), radial-gradient(ellipse farthest-corner at right bottom, var(--color-red) 0%, var(--color-red) 8%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, var(--color-red) 8%, var(--color-red) 25%, var(--color-red) 62.5%, var(--color-red) 100%);

    }

    &.taken-first {
//      background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
      color: #453f19;
      background: radial-gradient(ellipse at 46% 46%, rgb(224 221 208 / 99%) 0%, rgba(255 255 255 / 80%) 37%, rgba(255,255,255,0) 38%), radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
    }
  }
}



.stats {
  p {
    margin: 0.25em 0;
    font-size: 0.75em;

    &.gold {
      background: -webkit-linear-gradient(#FEDB37, #FFFFAC, #9f7928);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>
