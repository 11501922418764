export const deg2rad = deg => deg * (Math.PI / 180);
export const rad2deg = rad => rad * (180 / Math.PI);

export const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c * 1000; // Distance in meters
  return d;
};

export const getBearingBetweenCoordinates = (lat1, long1, lat2, long2) => {
  const dLon = (long2 - long1);

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  let bearing = Math.atan2(y, x); // Angle in radians

  bearing = rad2deg(bearing);
  bearing = (bearing + 360) % 360;
  // bearing = 360 - bearing; // count degrees counter-clockwise - remove to make clockwise

  return bearing; // Returns angle in degrees
};
